import { Container } from "@material-ui/core";
import React from "react";

interface ISpaceContainerProps {
	children: React.ReactElement;
}

export default function SpaceContainer(props: ISpaceContainerProps) {
	const containerStyle = {
		marginTop: "100px",
	};

	return (
		<>
			<Container style={containerStyle} maxWidth={false}>
				{props.children}
			</Container>
		</>
	);
}
