import { Grid } from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { createCompany } from "../../../services/api/companies";
import { ICompany } from "../../../types/Company.types";
import { createCompanyEmptyForm, ICreateCompanyForm } from "./forms";

interface ICompanySignUpForm {
	nextStepHandler: any;
	companySetter: any;
}

function CompanySignUpForm(props: ICompanySignUpForm) {
	function FormFields() {
		const { control, handleSubmit } = useForm<ICreateCompanyForm>();

		const onSubmit: SubmitHandler<ICreateCompanyForm> = async (
			formData: ICreateCompanyForm
		) => {
			const company = {
				name: formData.name,
				taxId: formData.taxId,
				phone: formData.phone,
				email: formData.email,
				address: {
					street: formData.address_street,
					number: formData.address_number,
					complement: formData.address_complement,
					neighborhood: formData.address_neighborhood,
					city: formData.address_city,
					state: formData.address_state,
					zipCode: formData.address_zipCode,
				},
				status: "draft",
				plan: "free_trial",
			} as ICompany;
			try {
				props.companySetter(company.taxId);
				props.nextStepHandler();
				await createCompany(company);
			} catch (error: any) {
				console.error(error);
			}
		};

		return (
			<>
				<form id="companySignUp" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Controller
								name="name"
								control={control}
								defaultValue={createCompanyEmptyForm.name}
								render={({ field: { onChange, value } }) => (
									<TextField
										name="name"
										required
										variant="standard"
										fullWidth
										id="name"
										label="Nome da Empresa"
										value={value}
										onChange={onChange}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="taxId"
								control={control}
								defaultValue={createCompanyEmptyForm.taxId}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										fullWidth
										value={value}
										id="taxId"
										label="CNPJ da Empresa"
										name="taxId"
										type="tel"
										onChange={onChange}
										inputProps={{
											maxlength: 14,
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="phone"
								control={control}
								defaultValue={createCompanyEmptyForm.phone}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										fullWidth
										type="tel"
										id="phone"
										value={value}
										label="Telefone"
										name="phone"
										onChange={onChange}
										inputProps={{
											maxlength: 11,
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="email"
								control={control}
								defaultValue={createCompanyEmptyForm.email}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										fullWidth
										value={value}
										id="email"
										type="email"
										label="Email"
										name="email"
										onChange={onChange}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="address_street"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_street
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										value={value}
										variant="standard"
										fullWidth
										id="address_street"
										label="Endereço"
										onChange={onChange}
										name="address_street"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Controller
								name="address_number"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_number
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										value={value}
										fullWidth
										id="address_number"
										label="Nº"
										onChange={onChange}
										name="address_number"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Controller
								name="address_complement"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_complement
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										variant="standard"
										fullWidth
										value={value}
										id="address_complement"
										label="Complemento"
										onChange={onChange}
										name="address_complement"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Controller
								name="address_neighborhood"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_neighborhood
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										value={value}
										fullWidth
										id="address_neighborhood"
										label="Bairro"
										onChange={onChange}
										name="address_neighborhood"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controller
								name="address_city"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_city
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										fullWidth
										id="address_city"
										label="Cidade"
										onChange={onChange}
										value={value}
										name="address_city"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controller
								name="address_state"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_state
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										onChange={onChange}
										variant="standard"
										fullWidth
										id="address_state"
										value={value}
										label="Estado"
										name="address_state"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="address_zipCode"
								control={control}
								defaultValue={
									createCompanyEmptyForm.address_zipCode
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										required
										variant="standard"
										onChange={onChange}
										fullWidth
										type="tel"
										value={value}
										id="address_zipCode"
										label="CEP"
										name="address_zipCode"
										inputProps={{
											maxlength: 8,
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						style={{ marginTop: "16px" }}
					>
						CADASTRAR
					</Button>
					{/* <Grid container justifyContent="flex-end">
					<Grid item>
						<br />
						<Link style={{ color: "#ff7221" }} to="/">
							Já possui uma conta? Faça login.
						</Link>
					</Grid>
				</Grid> */}
				</form>
			</>
		);
	}

	return (
		<div>
			<Grid item xs={12}>
				<FormFields />
			</Grid>
		</div>
	);
}

export { CompanySignUpForm };
