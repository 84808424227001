import {
	Button,
	Chip,
	Grid,
	IconButton,
	Slider,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../../components/Loading";
import FormModal from "../../../../../../../components/Modals/FormModal";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../../components/Snackbars/SmartSnackbar";
import { Header } from "../../../../../../../components/Typography/Typography";
import { AuthContext } from "../../../../../../../modules/auth/Auth";
import { updateGroupService } from "../../../../../../../services/api/models";
import { getCurrentPrice } from "../../../../../../../services/api/prices";
import {
	IGroupServiceDto,
	IServiceDto,
} from "../../../../../../../types/Services.types";
import { parseDecimalToHoursAndMinutes } from "../../../../../../../utils/helpers/parsers";
import { IGroupCreationForm, emptyGroupForm } from "../GroupForm.form";
import GroupServiceForm from "./GroupServiceForm";

interface IGroupServicesServiceDetailsData extends IServiceDto {
	percentage: number;
}

interface IGroupServiceDetailsData {
	system: string;
	services: IGroupServicesServiceDetailsData[];
	compiledTime: number;
}

interface IChangeLog {
	type: "percentage" | "addition" | "removal";
	serviceId: string;
}

export interface IGroupDetailsProps {
	modelId: string;
	group: IGroupServiceDto;
	modelServices: IServiceDto[];
	refreshParentHandler: any;
	refreshParent: boolean;
	closeModal: any;
	adjustmentFactor?: number;
}

interface IValueLabelProps {
	children: React.ReactElement;
	open: boolean;
	value: number;
}

function GroupDetails(props: IGroupDetailsProps) {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [data, setData] = useState<IGroupServiceDetailsData[]>([]);
	const [sellPrice, setSellPrice] = useState<number>(0);
	const [modelPrice, setModelPrice] = useState<number>(0);
	const [changeLog, setChangeLog] = useState<IChangeLog[]>([]);
	const [pendencies, setPendencies] = useState<number>(0);
	const { control, handleSubmit, reset } = useForm<IGroupCreationForm>({
		defaultValues: emptyGroupForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});
	const { companyTaxId } = useContext(AuthContext);

	const priceData = useCallback(async () => {
		const price = await getCurrentPrice(companyTaxId!);
		setSellPrice(price?.sellPrice ?? 0);
	}, [companyTaxId]);

	useEffect(() => {
		priceData();
		setModelPrice((props.adjustmentFactor ?? 0) * sellPrice!);
	}, [priceData, props.adjustmentFactor, sellPrice]);

	function organizeServices(services: IGroupServiceDetailsData[]) {
		services = services.sort((a, b) =>
			a.system > b.system ? 1 : b.system > a.system ? -1 : 0
		);

		services.forEach((s) => {
			let sum = 0;
			s.services.forEach((service) => {
				sum += service.duration * service.percentage;
			});
			s.compiledTime = sum;
		}, []);

		setData(services);
	}

	function addChange(
		serviceId: any,
		type: "percentage" | "addition" | "removal"
	) {
		let changeLogNewArr = changeLog;

		const searchIndex = changeLog.findIndex(
			(c) => c.serviceId === serviceId
		);

		if (searchIndex === -1) {
			changeLogNewArr.push({
				type,
				serviceId,
			});

			setChangeLog(changeLogNewArr);
			setPendencies(changeLogNewArr.length);
		}
	}

	function addServiceHandler(e: any) {
		let service = props.modelServices.find((x) => x.id === e.id)!;
		const serviceToAdd = { ...service, percentage: e.percentage };

		let systemToUpdate = data!.find((sys: IGroupServiceDetailsData) => {
			return sys.system === serviceToAdd.system;
		}) ?? {
			system: serviceToAdd.system,
			services: [] as IGroupServicesServiceDetailsData[],
			compiledTime: service.duration * e.percentage,
		};

		let serviceExists = systemToUpdate!.services.find((s) => {
			return s.id === e.id;
		});

		if (!serviceExists) {
			let updatedSystems = data.filter(function (el) {
				return el.system !== serviceToAdd.system;
			});

			systemToUpdate!.services.push(serviceToAdd);
			updatedSystems.push(systemToUpdate!);
			organizeServices(updatedSystems);
			addChange(e.id, "addition");
		} else {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Este serviço já existe no grupo",
				severity: "error",
			});
		}
	}

	function updateServicePercentage(serviceId: string, v: number) {
		let service = props.modelServices.find((x) => x.id === serviceId)!;

		let systemToUpdate = data!.find((sys: IGroupServiceDetailsData) => {
			return sys.system === service.system;
		});

		let filteredSystems = data.filter((el) => {
			return el.system !== systemToUpdate!.system;
		});

		let newServices = systemToUpdate!.services.filter(
			(s) => s.id !== serviceId
		);
		newServices.push({ ...service, percentage: v / 100 });

		const newSeystem = { ...systemToUpdate!, services: newServices };

		filteredSystems.push(newSeystem);
		organizeServices(filteredSystems);
		addChange(serviceId, "percentage");
	}

	const onSubmit: SubmitHandler<IGroupCreationForm> = async (
		formData: IGroupCreationForm
	) => {
		setLoading(true);
		try {
			const payload = {
				name: formData.name,
			};

			await updateGroupService(props.modelId, payload, props.group.name);
			setChangeLog([]);
			setPendencies(0);
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Alterações salvas",
				severity: "success",
			});
			props.refreshParentHandler(!props.refreshParent);
			props.closeModal();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	async function removeService(system: string, serviceId: string) {
		try {
			let systemToUpdate = data!.find((sys: IGroupServiceDetailsData) => {
				return sys.system === system;
			});
			let updatedSystems = data.filter(function (el) {
				return el.system !== system;
			});

			let oldServices = systemToUpdate!.services.filter(function (el) {
				return el.id !== serviceId;
			});

			if (oldServices.length > 0) {
				systemToUpdate!.services = oldServices;
				updatedSystems.push(systemToUpdate!);
			}

			organizeServices(updatedSystems);
			addChange(serviceId, "removal");

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Alterações salvas",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
		}
	}

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
		}
	}

	function ValueLabelComponent(props: IValueLabelProps) {
		const { children, open, value } = props;

		return (
			<Tooltip
				open={open}
				enterTouchDelay={0}
				placement="right"
				title={value}
			>
				{children}
			</Tooltip>
		);
	}

	function valuetext(value: number) {
		return `${value}%`;
	}

	useEffect(() => {
		reset(props.group);
		let parsedServices: IGroupServiceDetailsData[] = [];

		const services = props.group.services.map((service) => {
			const serviceDto = props.modelServices.find(
				(x) => x.id === service.id
			)!;
			return {
				...serviceDto,
				percentage: service.percentage,
			};
		});

		const systems = services
			.map((s) => s.system)
			.filter((value, index, self) => self.indexOf(value) === index);

		systems?.forEach((system) => {
			const systemServices = services
				.filter(
					(s: IGroupServicesServiceDetailsData) => s.system === system
				)
				.map((s: IGroupServicesServiceDetailsData) => {
					return {
						...s,
					};
				});

			parsedServices.push({
				system: system,
				services: systemServices,
				compiledTime: systemServices.reduce((sum, service) => {
					return sum + service.duration * service.percentage;
				}, 0),
			} as IGroupServiceDetailsData);
		});

		organizeServices(parsedServices);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.group, props.group.services, props.modelServices, reset]);

	useEffect(() => {
		let totalTime = 0;
		data.forEach((sys) => {
			totalTime += sys.compiledTime;
		});
		setTotalPrice(totalTime * modelPrice);
	}, [data, modelPrice]);

	return (
		<>
			{loading && <Loading />}

			<Grid
				container
				direction="row"
				spacing={2}
				justifyContent="space-between"
				style={{ marginBottom: "16px" }}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={9}
					lg={10}
					direction="column"
					justifyContent="flex-end"
				>
					<Chip
						style={{
							backgroundColor: `#${
								pendencies > 0 ? "212121" : "ff7221"
							}`,
							color: "white",
						}}
						label={
							pendencies > 0
								? "Há alterações não salvas"
								: "Não há alterações"
						}
					/>

					<form
						id="groupDetailsForm"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="name"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="name"
									label="Nome do Grupo"
									type="text"
									fullWidth
									style={{ marginBottom: "0px" }}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					</form>
				</Grid>

				<Grid
					container
					justifyContent="space-between"
					direction="column"
					item
					xs={12}
					sm={12}
					md={3}
					lg={2}
				>
					<Chip
						style={{
							backgroundColor: "#ff7221",
							color: "white",
						}}
						label={`R$ ${totalPrice
							.toFixed(2)
							.toString()
							.replace(".", ",")}`}
					/>

					<Button
						variant="contained"
						color="primary"
						endIcon={<PlaylistAdd />}
						size="small"
						onClick={() => setOpenModal(true)}
					>
						Adicionar Serviço{" "}
					</Button>
				</Grid>
			</Grid>

			{data?.map((s) => (
				<>
					<Header text={s.system} />
					<Table
						style={{ marginBottom: "8px" }}
						stickyHeader
						size="small"
						aria-label="a dense table"
					>
						<TableHead>
							<TableRow>
								<TableCell width={"5%"}></TableCell>
								<TableCell width={"55%"}>Serviço</TableCell>
								<TableCell width={"10%"} align="center">
									Tempo
								</TableCell>
								<TableCell width={"15%"} align="center">
									Percentual
								</TableCell>
								<TableCell width={"15%"} align="right">
									Valor (R$)
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{s.services.map((s, index) => (
								<TableRow
									key={`${s.description
										.replace(" ", "_")
										.toLowerCase()}-${index}`}
								>
									<TableCell>
										<IconButton
											color="default"
											aria-label="delete"
											size="small"
											onClick={() =>
												removeService(s.system, s.id)
											}
										>
											<HighlightOffIcon />
										</IconButton>
									</TableCell>
									<TableCell>{s.description}</TableCell>
									<TableCell align="center">
										{parseDecimalToHoursAndMinutes(
											s.duration * s.percentage
										)}
									</TableCell>
									<TableCell align="center">
										<Slider
											min={0}
											max={100}
											defaultValue={s.percentage * 100}
											valueLabelDisplay="auto"
											valueLabelFormat={valuetext}
											ValueLabelComponent={
												ValueLabelComponent
											}
											onChangeCommitted={(_e, v) =>
												updateServicePercentage(
													s.id,
													v as number
												)
											}
										/>
									</TableCell>
									<TableCell align="right">
										R${" "}
										{(
											s.duration *
											s.percentage *
											modelPrice
										)
											.toFixed(2)
											.toString()
											.replace(".", ",")}
									</TableCell>
								</TableRow>
							))}

							<TableRow
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="center">
									<Typography
										variant="subtitle1"
										color="primary"
									>
										Subtotal
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography
										variant="subtitle1"
										color="primary"
									>
										R${" "}
										{(modelPrice * s.compiledTime)
											.toFixed(2)
											.toString()
											.replace(".", ",")}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</>
			))}

			{/* FormModal de adição e edição de serviço */}
			<FormModal
				title="Adicionar serviço"
				description="Adicione um serviço ao grupo"
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="groupServiceForm"
				actionText="Adicionar Serviço"
			>
				<GroupServiceForm
					services={props.modelServices}
					id={props.modelId}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
					groupName={props.group.name}
					addServiceHandler={addServiceHandler}
				/>
			</FormModal>

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}

export default GroupDetails;
