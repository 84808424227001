import { uuidv4 } from "@firebase/util";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../components/Snackbars/SmartSnackbar";
import { addServiceTemplate } from "../../../../services/api/templates";
import { IServiceTemplate } from "../../../../types/ServiceTemplates.types";
import {
	IServiceTemplateCreationForm,
	emptyServiceTemplateForm,
} from "./ServiceTemplateForm.form";

export interface IServiceTemplateFormProps {
	refreshParentHandler?: any;
	refreshParent?: boolean;
	id?: string;
	setId?: any;
	handleCloseModal?: any;
}

export default function ServiceTemplateForm(props: IServiceTemplateFormProps) {
	const { control, handleSubmit, reset } =
		useForm<IServiceTemplateCreationForm>({
			defaultValues: emptyServiceTemplateForm,
		});
	const [loading, setLoading] = useState<boolean>(false);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyServiceTemplateForm);
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IServiceTemplateCreationForm> = async (
		data: IServiceTemplateCreationForm
	) => {
		setLoading(true);
		try {
			const payload: IServiceTemplate = {
				createdBy: "",
				id: uuidv4(),
				createdDate: new Date(),
				description: data.description,
				services: [],
				serviceIds: [],
			};

			await addServiceTemplate(payload);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Grupo adicionado com sucesso",
				severity: "success",
			});

			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}
			<form id="serviceTemplateForm" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="description"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							autoFocus
							margin="dense"
							id="description"
							label="Nome do Grupo"
							type="text"
							fullWidth
							onChange={onChange}
							value={value}
						/>
					)}
				/>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { ServiceTemplateForm };
