import { Grid } from "@material-ui/core";

interface ICardsGridProps {
	children?: JSX.Element;
}

export default function CardsGrid(props: ICardsGridProps) {
	return (
		<Grid
			container
			spacing={2}
			direction="row"
			justifyContent="flex-start"
			alignItems="stretch"
		>
			{props.children}
		</Grid>
	);
}
