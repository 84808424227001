import { Grid } from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import { useCallback } from "react";
import { createUser } from "../../../services/api/users";

interface IUserSignUpProps {
	companyTaxId: string;
	nextStepHandler: any;
	succefullyCreatedCallback: any;
}

function FormFields(props: IUserSignUpProps) {
	const handleSignUp = useCallback(
		async (event: any) => {
			event.preventDefault();
			const { name, surname, email, password, phone } =
				event.target.elements;
			const user = {
				name: name.value,
				surname: surname.value,
				email: email.value,
				password: password.value,
				phone: phone.value,
				company: props.companyTaxId,
				isAdmin: true,
			};
			try {
				await createUser(user);
				props.nextStepHandler();
			} catch (error: any) {
				props.succefullyCreatedCallback(false);
				console.error(error);
			} finally {
				props.nextStepHandler();
			}
		},
		[props]
	);

	return (
		<form onSubmit={handleSignUp}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						name="name"
						required
						variant="standard"
						fullWidth
						id="name"
						label="Nome"
						autoFocus
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="surname"
						label="Sobrenome"
						name="surname"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="phone"
						label="Celular"
						name="phone"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						fullWidth
						id="email"
						label="Email"
						name="email"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						color="primary"
						fullWidth
						name="password"
						label="Senha"
						type="password"
						helperText="Mínimo de 6 caracteres"
						id="password"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="standard"
						color="primary"
						fullWidth
						name="passwordConfirmation"
						label="Confirmar Senha"
						type="password"
						id="password_confirmation"
						inputProps={{ style: { color: "#ff7221" } }}
					/>
				</Grid>
			</Grid>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				style={{ marginTop: "16px" }}
			>
				CADASTRAR
			</Button>
			{/* <Grid container justifyContent="flex-end">
				<Grid item>
					<br />
					<Link style={{ color: "#ff7221" }} to="/">
						Já possui uma conta? Faça login.
					</Link>
				</Grid>
			</Grid> */}
		</form>
	);
}

function UserSignUpForm(props: IUserSignUpProps) {
	return (
		<div>
			{/* <Grid item xs={12}>
				<h1 style={{ color: "#ff7221" }}>Cadastre-se</h1>
			</Grid> */}

			<Grid item xs={12}>
				<FormFields
					succefullyCreatedCallback={props.succefullyCreatedCallback}
					nextStepHandler={props.nextStepHandler}
					companyTaxId={props.companyTaxId}
				/>
			</Grid>
		</div>
	);
}

export { UserSignUpForm };
