import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../assets/logo.png";

const pages = ["Ferramentas", "Planos", "Contato", "Cadastrar"];

function LandingNavBar() {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const navigate = useNavigate();
	const handleOpenNavMenu = (event: any) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar
			elevation={0}
			position="fixed"
			style={{ zIndex: 15, height: 70 }}
		>
			<Toolbar variant="dense">
				<Link
					to={"/orders"}
					style={{ marginLeft: "24px", marginRight: "16px" }}
				>
					<img
						style={{ zIndex: 20 }}
						src={logoImage}
						alt="Logo Engine Pro"
						height={30}
					/>
				</Link>

				<Box
					sx={{
						flexGrow: 1,
						height: 60,
						display: { xs: "flex", md: "none" },
					}}
				>
					<IconButton
						size="large"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: "block", md: "none" },
						}}
					>
						{pages.map((page) => (
							<MenuItem key={page} onClick={handleCloseNavMenu}>
								<Typography textAlign="center">
									{page}
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						display: { xs: "none", md: "flex" },
					}}
				></Box>

				<Box
					sx={{
						flexGrow: 1,
						display: {
							justifyContent: "flex-end",
							xs: "none",
							md: "flex",
						},
					}}
				>
					{pages.map((page) => (
						<Button
							key={page}
							onClick={handleCloseNavMenu}
							sx={{
								my: 2,
								color: "white",
								display: "block",
							}}
						>
							{page}
						</Button>
					))}
					<Button
						key="login"
						variant="contained"
						onClick={() => navigate("login")}
						sx={{
							my: 2,
							backgroundColor: "#ffffff",
							color: "#ff7221",
							display: "block",
							":hover": {
								backgroundColor: "#303030",
								color: "#ffffff",
							},
						}}
					>
						ENTRAR
					</Button>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
export default LandingNavBar;
