import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { fullfillModelOnManufacturer } from "../../../services/api/manufacturers";
import {
	editModel,
	formalizeModelOrders,
	getModelById,
} from "../../../services/api/models";
import { getDataByModelYear, getModelYears } from "../../../services/fipe/fipe";
import { calcSpecificValues } from "../../../services/functions/predictions";
import { ICarModelCreation } from "../../../types/CarModel.types";
import { ITodosValores } from "../../../types/Fipe.types";
import { IOptions } from "../../../types/Options.types";
import { modelAttributes } from "../../../utils/data/modelProperties";
import { IModelFormInput, emptyModelForm } from "./ModelsForm.form";

interface IModelFormProps {
	id: string;
}

export default function ModelsForm(props: IModelFormProps) {
	const { control, handleSubmit, reset, formState, getValues } =
		useForm<IModelFormInput>();
	const [data, setData] = useState<IModelFormInput>(emptyModelForm);
	const [loading, setLoading] = useState<boolean>(false);
	const [enableFinalButtons, setEnableFinalButtons] =
		useState<boolean>(false);
	const [enableCalculate, setEnableCalculate] = useState<boolean>(false);
	const [enableFipeSearch, setEnableFipeSearch] = useState<boolean>(true);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	useEffect(() => {
		if (formState.isDirty) {
			setEnableFinalButtons(false);
		}
	}, [formState.isDirty]);

	const clearForm = () => {
		modelData();
		setEnableFinalButtons(false);
		setEnableFipeSearch(true);
	};

	const onGetFipeData = async () => {
		try {
			setLoading(true);

			var yearsRes = await getModelYears(
				data.manufacturerFipeCode,
				data.insideManufacturerCode
			);
			var yearsData: ITodosValores[] = [];

			for (let i = 0; i < yearsRes.length; i++) {
				const yearData = await getDataByModelYear(
					data.manufacturerFipeCode,
					data.insideManufacturerCode,
					yearsRes[i].Value
				);
				yearsData.push(yearData);
			}

			var years = yearsData.map((y: ITodosValores) =>
				parseInt(y.AnoModelo)
			);
			var fipeCodesData = yearsData
				.map((y: ITodosValores) => ({
					codigoFipe: y.CodigoFipe,
					modelo: y.Modelo,
				}))
				.filter(
					(value, index, self) =>
						index ===
						self.findIndex(
							(t) =>
								t.codigoFipe === value.codigoFipe &&
								t.modelo === value.modelo
						)
				);

			const orderedByYear = yearsData.sort(
				(y1: ITodosValores, y2: ITodosValores) => {
					return y1.AnoModelo >= y2.AnoModelo ? 1 : -1;
				}
			);

			const highestPrice = parseFloat(
				orderedByYear[orderedByYear.length - 1].Valor.replace("R$ ", "")
					.replace(".", "")
					.replace(",", ".")
			);
			const newFormInputs: IModelFormInput = {
				...data,
				fipeCodes: fipeCodesData,
				years: years,
				fipePrice: highestPrice,
			};
			setData(newFormInputs);
			setEnableCalculate(true);
			setEnableFipeSearch(false);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onCalculate = async () => {
		try {
			setLoading(true);
			const formValues = getValues();

			let calcValuesInput = (({ version, ...formValues }) => formValues)(
				formValues
			);

			calcValuesInput.manufacturerId = data.manufacturerName;

			const res = await calcSpecificValues(calcValuesInput);
			setData({
				...formValues,
				dealershipEstimatedPrice: res!.manufacturerPrice.toFixed(2),
				adjustmentFactor: res!.adjustmentFactor.toFixed(7),
			});
			setEnableFinalButtons(true);
			setEnableCalculate(false);
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message:
					"Erro ao realizar cálculo. Entre em contato com o desenvolvedor.",
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onSubmit: SubmitHandler<IModelFormInput> = async (
		data: IModelFormInput
	) => {
		setLoading(true);

		try {
			const payload: ICarModelCreation = {
				name: data.name,
				manufacturerName: data.manufacturerName,
				manufacturerFipeCode: data.manufacturerFipeCode,
				transmission: data.transmission,
				fipeCodes: data.fipeCodes,
				fuel: data.fuel,
				fullfilled: true,
				fipePrice: data.fipePrice,
				years: data.years,
				engineCapacity: data.engineCapacity,
				cilinderAmount: data.cilinderAmount,
				cilindersDistribuition: data.cilindersDistribuition,
				valvesPerCilinder: data.valvesPerCilinder,
				feeding: data.feeding,
				bodyType: data.bodyType,
				origin: data.origin,
				bodyCategory: data.bodyCategory,
				premium: data.premium,
				dealershipEstimatedPrice: data.dealershipEstimatedPrice,
				adjustmentFactor: data.adjustmentFactor,
				services: [],
				serviceGroups: [],
			};

			await editModel(props.id, payload);
			await fullfillModelOnManufacturer(
				data.manufacturerFipeCode,
				props.id.split("_")[1],
				data.years,
				data.fipeCodes
			);
			await formalizeModelOrders(data.fipeCodes, data.adjustmentFactor);
			setEnableFinalButtons(false);
			setEnableFipeSearch(true);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: props.id
					? "Veículo editado com sucesso"
					: "Veículo adicionado com sucesso",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "success",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const modelData = useCallback(async () => {
		try {
			if (!!props?.id) {
				const modelData = await getModelById(props.id);
				setData(modelData as IModelFormInput);
			} else {
				reset(emptyModelForm);
			}
		} catch (error: any) {
			console.error(error);
		}
	}, [props.id, reset]);

	useEffect(() => {
		modelData();
	}, [modelData]);

	useEffect(() => {
		reset(data);
	}, [data, reset]);

	return (
		<>
			{loading && <Loading />}

			<form id="modelForm" onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Typography variant="subtitle1">Dados Gerais</Typography>
				</Grid>
				<Grid
					container
					spacing={1}
					justifyContent="flex-start"
					alignItems="flex-end"
				>
					<Grid item xs={12} sm={4}>
						<Controller
							name="manufacturerId"
							control={control}
							defaultValue={emptyModelForm.manufacturerName}
							render={() => (
								<TextField
									margin="dense"
									id="manufacturerName"
									label="Montadora"
									type="text"
									fullWidth
									InputProps={{
										readOnly: true,
									}}
									value={data.manufacturerName}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Controller
							name="name"
							control={control}
							defaultValue={emptyModelForm.name}
							render={() => (
								<TextField
									autoFocus
									margin="dense"
									id="model"
									label="Modelo"
									type="text"
									fullWidth
									InputProps={{
										readOnly: true,
									}}
									value={data.name}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Controller
							name="fipePrice"
							control={control}
							defaultValue={emptyModelForm.fipePrice}
							render={({ field: { onChange, value } }) => (
								<TextField
									autoFocus
									margin="dense"
									id="fipePrice"
									label="Preço Tabela FIPE"
									fullWidth
									type="number"
									InputProps={{
										inputProps: { min: 0 },
										startAdornment: {
											...(
												<InputAdornment position="start">
													R$
												</InputAdornment>
											),
										},
									}}
									onChange={onChange}
									value={value === 0 ? "" : value}
								/>
							)}
						/>
					</Grid>

					<Grid
						container
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="subtitle1"
							style={{ marginTop: "24px" }}
						>
							Características
						</Typography>
					</Grid>
					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Grid item xs={12} sm={3}>
							<Controller
								name="engineCapacity"
								control={control}
								defaultValue={emptyModelForm.engineCapacity}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="engineCapacity"
										label="Cilindrada"
										type="number"
										InputProps={{
											inputProps: {
												min: 0,
												step: 0.1,
											},
										}}
										fullWidth
										onChange={onChange}
										value={value === 0 ? "" : value}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="cilinderAmount"
								control={control}
								defaultValue={emptyModelForm.cilinderAmount}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="cilinderAmount"
										label="Número de cilindros"
										type="number"
										inputProps={{ min: 0, step: 1 }}
										fullWidth
										onChange={onChange}
										value={value === 0 ? "" : value}
									/>
								)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="fuel">
									Disposição dos cilindros
								</InputLabel>

								<Controller
									control={control}
									name="cilindersDistribuition"
									defaultValue={
										emptyModelForm.cilindersDistribuition
									}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="cilindersDistribuition"
											id="select-cilindersDistribuition"
											fullWidth
											onChange={onChange}
											value={value}
										>
											{modelAttributes.cilindersDistribuitionOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid
							item
							xs={12}
							sm={3}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="transmission">
									Alimentação
								</InputLabel>

								<Controller
									control={control}
									name="feeding"
									defaultValue={emptyModelForm.feeding}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="feeding"
											id="select-feeding"
											fullWidth
											onChange={onChange}
											value={value}
										>
											{modelAttributes.engineFeedingOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						container
						spacing={1}
						justifyContent="flex-start"
						alignItems="flex-end"
					>
						<Grid item xs={12} sm={3}>
							<Controller
								name="valvesPerCilinder"
								control={control}
								defaultValue={emptyModelForm.cilinderAmount}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="valvesPerCilinder"
										label="Válvulas por cilindro"
										type="number"
										inputProps={{ min: 0, step: 1 }}
										fullWidth
										onChange={onChange}
										value={value === 0 ? "" : value}
									/>
								)}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={3}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="fuel">Combustível</InputLabel>

								<Controller
									control={control}
									name="fuel"
									defaultValue={emptyModelForm.fuel}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="fuel"
											id="select-fuel"
											fullWidth
											onChange={onChange}
											value={value}
										>
											{modelAttributes.fuelOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid
							item
							xs={12}
							sm={3}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="transmission">
									Transmissão
								</InputLabel>

								<Controller
									control={control}
									name="transmission"
									defaultValue={emptyModelForm.transmission}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="transmission"
											id="select-transmission"
											fullWidth
											onChange={onChange}
											value={value}
										>
											{modelAttributes.trasnmissionOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						container
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="subtitle1"
							style={{ marginTop: "24px" }}
						>
							Categorias
						</Typography>
					</Grid>

					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Grid
							item
							xs={12}
							sm={4}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="origin">Procedência</InputLabel>

								<Controller
									control={control}
									name="origin"
									defaultValue={emptyModelForm.origin}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="origin"
											id="select-origin"
											onChange={onChange}
											value={value}
											fullWidth
										>
											{modelAttributes.originOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid
							item
							xs={12}
							sm={4}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="bodyType">
									Tipo de carroceria
								</InputLabel>

								<Controller
									control={control}
									name="bodyType"
									defaultValue={emptyModelForm.bodyType}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="bodyType"
											id="select-bodyType"
											onChange={onChange}
											value={value}
											fullWidth
										>
											{modelAttributes.bodyTypeOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid
							item
							xs={12}
							sm={4}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="bodyCategory">
									Categoria da carroceria
								</InputLabel>

								<Controller
									control={control}
									name="bodyCategory"
									defaultValue={emptyModelForm.bodyCategory}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="bodyCategory"
											id="select-bodyCategory"
											onChange={onChange}
											value={value}
											fullWidth
										>
											{modelAttributes.bodyCategoryOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						container
						spacing={1}
						justifyContent="flex-start"
						alignItems="flex-end"
					>
						<Grid
							item
							xs={12}
							sm={4}
							style={{ marginBottom: "4px" }}
						>
							<FormControl fullWidth>
								<InputLabel id="premium">Premium</InputLabel>

								<Controller
									control={control}
									name="premium"
									defaultValue={emptyModelForm.premium}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="premium"
											id="select-premium"
											onChange={onChange}
											value={value}
											fullWidth
										>
											{modelAttributes.booleanOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						container
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="subtitle1"
							style={{ marginTop: "24px" }}
						>
							Valores/Hora
						</Typography>
					</Grid>
					<Grid
						container
						spacing={1}
						justifyContent="flex-start"
						alignItems="flex-end"
					>
						<Grid item xs={12} sm={4}>
							<Controller
								name="dealershipEstimatedPrice"
								control={control}
								defaultValue={
									emptyModelForm.dealershipEstimatedPrice
								}
								render={({ field: { onChange, value } }) => (
									<TextField
										disabled
										autoFocus
										margin="dense"
										id="dealershipEstimatedPrice"
										label="Estimativa concessionária"
										fullWidth
										type="number"
										InputProps={{
											inputProps: { min: 0 },
											startAdornment: {
												...(
													<InputAdornment position="start">
														R$
													</InputAdornment>
												),
											},
										}}
										onChange={onChange}
										value={value === 0 ? "" : value}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Controller
								name="adjustmentFactor"
								control={control}
								defaultValue={
									emptyModelForm.dealershipEstimatedPrice
								}
								render={function ({
									field: { onChange, value },
								}) {
									return (
										<TextField
											disabled
											autoFocus
											margin="dense"
											id="adjustmentFactor"
											label="Fator de Correção"
											fullWidth
											type="number"
											InputProps={{
												inputProps: { min: 0 },
												endAdornment: {
													...(
														<InputAdornment position="start">
															X
														</InputAdornment>
													),
												},
											}}
											onChange={onChange}
											value={value === 0 ? "" : value}
										/>
									);
								}}
							/>
						</Grid>
					</Grid>

					<Grid
						container
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="subtitle1"
							style={{ marginTop: "24px" }}
						>
							Veículos Contemplados
						</Typography>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Controller
							name="years"
							control={control}
							defaultValue={emptyModelForm.years}
							render={({ field: { value } }) => (
								<div style={{ textAlign: "left" }}>
									<Typography variant="body1">
										Anos
									</Typography>
									{value.length > 0 ? (
										<p>
											De {value[value.length - 1]} a{" "}
											{value[0]}
										</p>
									) : (
										<p>
											Finalize o cadastro do carro para
											exibir
										</p>
									)}
								</div>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Controller
							name="fipeCodes"
							control={control}
							defaultValue={emptyModelForm.fipeCodes}
							render={({ field: { value } }) => (
								<div style={{ textAlign: "left" }}>
									<Typography variant="body1">
										Códigos FIPE
									</Typography>
									{value.length > 0 ? (
										<>
											{value.map((v) => (
												<p>{`Código: ${v.codigoFipe} | Modelo: ${v.modelo}`}</p>
											))}
										</>
									) : (
										<p>
											Finalize o cadastro do carro para
											exibir
										</p>
									)}
								</div>
							)}
						/>
					</Grid>
				</Grid>

				<Grid
					style={{ marginTop: "24px" }}
					container
					spacing={1}
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Grid item>
						<Button
							onClick={() => onGetFipeData()}
							color="primary"
							disabled={!enableFipeSearch}
						>
							Buscar Dados FIPE
						</Button>

						<Button
							onClick={() => onCalculate()}
							color="primary"
							disabled={!enableCalculate}
						>
							Calcular
						</Button>
					</Grid>

					<Grid item>
						<Button
							disabled={!enableFinalButtons}
							onClick={() => {
								clearForm();
							}}
							color="primary"
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={!enableFinalButtons}
						>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</>
	);
}
