import { uuidv4 } from "@firebase/util";
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import {
	addService,
	getServiceDataById,
	updateService,
} from "../../../services/api/services";
import { IFormModalProps } from "../../../types/Modal.types";
import { IOptions } from "../../../types/Options.types";
import { IServiceDto, IServicePart } from "../../../types/Services.types";
import { serviceAttributes } from "../../../utils/data/serviceProperties";
import { IServiceFormInput, emptyServiceForm } from "./ServiceForm.form";

export interface IServiceFormProps extends IFormModalProps {
	serviceId?: string;
}

export default function ServiceForm(props: IServiceFormProps) {
	const { control, handleSubmit, reset } = useForm<IServiceFormInput>({
		defaultValues: emptyServiceForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [serviceData, setServiceData] = useState<IServiceDto>();
	const [serviceParts, setServiceParts] = useState<IServicePart[]>([]);
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const getServiceData = useCallback(async () => {
		try {
			if (!!props.serviceId) {
				const data = await getServiceDataById(props.serviceId);

				reset(data as IServiceFormInput);
				setServiceData(data as IServiceDto);
				const orderedParts = data!.parts.sort(
					(p1: IServicePart, p2: IServicePart) => {
						return (
							new Date(p1.createdDate).getTime() -
							new Date(p2.createdDate).getTime()
						);
					}
				);
				setServiceParts(orderedParts);
			}
		} catch (error) {
			console.error(error);
		}
	}, [props.serviceId, reset]);

	useEffect(() => {
		getServiceData();
	}, [getServiceData]);

	const addPart = () => {
		const parts = [...serviceParts];

		const newPart: IServicePart = {
			createdBy: "",
			id: uuidv4(),
			description: "",
			createdDate: new Date(),
			amount: 1,
		};

		parts.push(newPart);
		setServiceParts(parts);
	};

	const evaluateParts = (
		id: string,
		prop: "description" | "amount",
		value: string | number
	) => {
		let newParts = [...serviceParts];

		const part = newParts.find((p: IServicePart) => p.id === id);

		if (prop === "description") {
			part!.description = value as string;
		}

		if (prop === "amount") {
			part!.amount = value as number;
		}

		newParts = newParts.filter((p: IServicePart) => p.id !== id);

		newParts.push(part!);

		newParts = newParts.sort((p1: IServicePart, p2: IServicePart) => {
			return (
				new Date(p1.createdDate).getTime() -
				new Date(p2.createdDate).getTime()
			);
		});

		setServiceParts(newParts);
	};

	const removePart = (part: IServicePart) => {
		let newParts = [...serviceParts];

		newParts = newParts.filter((p: IServicePart) => p.id !== part.id);

		newParts = newParts.sort((p1: IServicePart, p2: IServicePart) => {
			return (
				new Date(p1.createdDate).getTime() -
				new Date(p2.createdDate).getTime()
			);
		});

		setServiceParts(newParts);
	};

	const handleClose = () => {
		reset(emptyServiceForm);
		props.handleCloseModal(false);
	};

	const onSubmit: SubmitHandler<IServiceFormInput> = async (
		data: IServiceFormInput
	) => {
		setLoading(true);
		try {
			const payload: IServiceDto = {
				createdBy: "",
				createdDate: serviceData?.createdDate ?? new Date(),
				id: serviceData?.id ?? uuidv4(),
				description: data.description,
				parts: serviceParts,
				duration: parseFloat(data.duration.toString()),
				system: data.system,
			};

			if (!!props.serviceId) {
				await updateService(props.serviceId, payload as IServiceDto);
			} else {
				await addService(payload);
			}

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Serviço adicionado com sucesso",
				severity: "success",
			});
			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="serviceForm" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="description"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							autoFocus
							margin="dense"
							id="description"
							label="Descrição"
							type="text"
							fullWidth
							onChange={onChange}
							value={value}
						/>
					)}
				/>
				<FormControl fullWidth>
					<InputLabel id="system">Sistema</InputLabel>

					<Controller
						control={control}
						name="system"
						defaultValue={emptyServiceForm.system}
						render={({ field: { onChange, value } }) => (
							<Select
								labelId="system"
								id="select-system"
								fullWidth
								onChange={onChange}
								value={value}
							>
								{serviceAttributes.systemOptions.map(
									(o: IOptions, i: number) => {
										return (
											<MenuItem key={i} value={o.id}>
												{o.value}
											</MenuItem>
										);
									}
								)}
							</Select>
						)}
					/>
				</FormControl>
				<Controller
					name="duration"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							autoFocus
							margin="dense"
							id="duration"
							label="Duração em horas"
							type="number"
							InputProps={{
								inputProps: {
									min: 0.1,
									step: 0.05,
								},
							}}
							fullWidth
							onChange={onChange}
							value={value === 0 ? "" : value}
						/>
					)}
				/>

				<Grid
					container
					justifyContent="space-between"
					style={{ marginTop: "24px" }}
				>
					<Grid item>
						<Typography variant="subtitle1">Peças</Typography>
					</Grid>

					<Grid item>
						<Button
							style={{
								backgroundColor: "#ff7221",
								color: "white",
							}}
							variant="contained"
							color="primary"
							endIcon={<SettingsSuggestIcon />}
							onClick={() => addPart()}
							size="small"
						>
							Nova Peça{" "}
						</Button>
					</Grid>
				</Grid>

				{serviceParts.map((p: IServicePart) => {
					return (
						<>
							<Grid
								justifyContent="space-between"
								alignItems="flex-end"
								spacing={2}
								container
							>
								<Grid xs={9} item>
									<TextField
										autoFocus
										margin="dense"
										id={`partsDescription${p.id}`}
										label="Descrição"
										type="text"
										fullWidth
										onChange={(e: any) =>
											evaluateParts(
												p.id,
												"description",
												e.target.value
											)
										}
										value={p.description}
									/>
								</Grid>
								<Grid xs={2} item>
									<TextField
										autoFocus
										margin="dense"
										id={`partsAmount${p.id}`}
										label="Quantidade"
										type="number"
										fullWidth
										value={p.amount}
										InputProps={{
											inputProps: {
												min: 1,
												step: 1,
											},
										}}
										onChange={(e: any) =>
											evaluateParts(
												p.id,
												"amount",
												e.target.value
											)
										}
									/>
								</Grid>
								<Grid alignItems="center" item xs={1}>
									<IconButton
										color="default"
										aria-label="delete"
										size="small"
										onClick={() => removePart(p)}
									>
										<HighlightOffIcon />
									</IconButton>
								</Grid>
							</Grid>
						</>
					);
				})}
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { ServiceForm };
