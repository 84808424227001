import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
} from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IOrderDto } from "../../types/Order.types";
import { IOrderPartsDto, IPartEstimateDto } from "../../types/Parts.types";
import BottomLine from "../Separators/BottomLine";
import { DiscreteHeader, Footer, Header } from "../Typography/Typography";

interface IOSCardProps {
	item: IOrderDto;
	price: number;
}

export default function OSCard(props: IOSCardProps) {
	let navigate = useNavigate();
	// const [servicesTotal, setServicesTotal] = useState<number>(0);
	const [partsTotal, setPartsTotal] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);

	useEffect(() => {
		let servicesSum = 0;
		let partsSum = 0;

		// props.item.services.forEach((s: IServiceDto) => {
		// 	servicesSum =
		// 		servicesSum +
		// 		s.duration * props.item.modelAdjustmentFactor * props.price;
		// });

		// props.item.proceedings.forEach((s: IProceedingDto) => {
		// 	servicesSum = servicesSum + s.value;
		// });

		props.item.parts.forEach((p: IOrderPartsDto) => {
			const bestOptionEstimate = p.estimates.find(
				(e: IPartEstimateDto) => {
					return e.isBestOption;
				}
			);

			if (!!bestOptionEstimate) {
				partsSum =
					partsSum +
					bestOptionEstimate.amount *
						bestOptionEstimate.sellUnitPrice;
			}
		});

		// setServicesTotal(servicesSum);
		setPartsTotal(partsSum);
		setTotal(servicesSum + partsSum);
	}, [props.item.parts, props.item.proceedings]);

	return (
		<Grid
			item
			xs={12}
			md={4}
			lg={4}
			style={{
				display: "flex",
				flexDirection: "column",
				marginBottom: "8px",
			}}
		>
			<Card
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					height: "100%",
				}}
			>
				<Grid container alignItems={"flex-end"}>
					<CardContent
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							padding: "8px",
						}}
					>
						<DiscreteHeader
							color="#ff7221"
							text={`#${props.item.sequentialId} ${
								props.item.externalId
									? `(ID Externo: ${props.item.externalId})`
									: ""
							}`}
							subtitle={props.item.plate.toUpperCase()}
						/>

						<Header text={props.item.manufacturer} />
						<Header text={props.item.model} />
						<DiscreteHeader
							color="#ff7221"
							text={`${props.item.createdDate.toLocaleDateString(
								"pt-br"
							)}`}
							// subtitle={props.item.plate.toUpperCase()}
						/>
						<BottomLine />
					</CardContent>
					<CardActions
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							spacing={1}
						>
							<Grid item>
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={() =>
										navigate(`/orders/${props.item.id!}`)
									}
								>
									Detalhes
								</Button>
							</Grid>
							<Grid item>
								{/* <Chip
									color="success"
									icon={<BuildIcon />}
									style={{ marginRight: "4px" }}
									label={`R$${servicesTotal.toFixed(
										2
									)}`.replace(".", ",")}
								/> */}
								<Chip
									color="success"
									icon={<SettingsIcon />}
									style={{ marginLeft: "4px" }}
									label={`R$${partsTotal.toFixed(2)}`.replace(
										".",
										","
									)}
								/>
							</Grid>

							<Grid>
								<Footer
									text={`R$${total.toFixed(2)}`.replace(
										".",
										","
									)}
								/>
							</Grid>
						</Grid>
					</CardActions>
				</Grid>
			</Card>
		</Grid>
	);
}
