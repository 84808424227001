import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { importTemplateIntoOrder } from "../../../../../../services/api/orders";
import { getTemplateById } from "../../../../../../services/api/templates";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IOptions } from "../../../../../../types/Options.types";
import { parseTemplateOptions } from "../../../../../../utils/helpers/parsers";
import {
	IOrderImportTemplateFormInput,
	emptyOrderImportTemplateForm,
} from "./OrderImportTemplateForm.form";

export interface IOrderImportTemplateFormProps extends IFormModalProps {
	modelPrice: number;
	proceedingId: string;
}

export default function OrderImportTemplateForm(
	props: IOrderImportTemplateFormProps
) {
	const { control, handleSubmit, reset } =
		useForm<IOrderImportTemplateFormInput>({
			defaultValues: emptyOrderImportTemplateForm,
		});
	const [loading, setLoading] = useState<boolean>(false);
	const [templateOptions, setTemplateOptions] = useState<IOptions[]>([]);
	const [template, setTemplate] = useState<string>("");
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyOrderImportTemplateForm);
		props.handleCloseModal(false);
	};

	const fetchTemplates = useCallback(async () => {
		try {
			const data = await getTemplateById("all_templates");
			setTemplateOptions(parseTemplateOptions(data!.templates));
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		fetchTemplates();
	}, [fetchTemplates]);

	useEffect(() => {
		setTemplateOptions([]);
	}, []);

	const onSubmit: SubmitHandler<IOrderImportTemplateFormInput> = async () => {
		setLoading(true);
		try {
			await importTemplateIntoOrder(
				props.id,
				template,
				props.proceedingId
			);

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Grupo adicionado com sucesso",
				severity: "success",
			});

			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
			props.refreshParentHandler(!props.refreshParent);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="orderServiceGroupForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<Controller
						control={control}
						name="groupName"
						defaultValue={emptyOrderImportTemplateForm.groupName}
						render={() => (
							<Autocomplete
								style={{ marginBottom: "16px" }}
								options={templateOptions}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								noOptionsText="Nenhum grupo de serviços encontrado"
								getOptionLabel={(option: any) => option.value}
								renderInput={(params: any) => (
									<TextField {...params} label="Grupo" />
								)}
								onChange={(_e: any, v: any) => {
									setTemplate(v ? v.id : "");
								}}
							/>
						)}
					/>
				</FormControl>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderImportTemplateForm };
