import {
	AppBar,
	Button,
	CssBaseline,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItemIcon,
	ListItemText,
	Menu,
	Toolbar,
} from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { AccountCircle } from "@material-ui/icons";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import MenuIcon from "@mui/icons-material/Menu";
// import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
// import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { ListItemButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import clsx from "clsx";
import "font-awesome/css/font-awesome.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoImage from "../assets/logo.png";
import { getUserByUuid, signOut } from "../services/api/users";
import { auth } from "../services/config/environments";

interface IDrawerProps {
	children?: React.ReactNode;
}

interface IMenuSection {
	description: string;
	routes: {
		text: string;
		icon: React.ReactChild;
		route: string;
		roles: string[];
	}[];
}

const ServicesMenuItems: IMenuSection = {
	description: "Operação",
	routes: [
		{
			text: "Orçamentos",
			icon: (
				<BuildCircleOutlinedIcon
					htmlColor="#ffffff"
					fontSize="medium"
				/>
			),
			route: "/orders",
			roles: ["admin", "orcamentista"],
		},
		// {
		// 	text: "Quadro OS",
		// 	icon: <ViewKanbanIcon fontSize="medium" htmlColor="#ffffff" />,
		// 	route: "/board",
		// 	roles: ["admin", "orcamentista"],
		// },
		// {
		// 	text: "Agendamentos",
		// 	icon: (
		// 		<CalendarMonthOutlinedIcon
		// 			fontSize="medium"
		// 			htmlColor="#ffffff"
		// 		/>
		// 	),
		// 	route: "/agendamentos",
		// 	roles: ["admin", "orcamentista"],
		// },
		// {
		// 	text: 'Pagamentos',
		// 	icon: <SvgIcon svgContent={icons.payments} />,
		// 	route: '/payments',
		// },
	],
};

// const VehicleMenuItems: IMenuSection = {
// 	description: "Cadastros",
// 	routes: [
// {
// 	text: "Modelos",
// 	icon: <DirectionsCarIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/modelos",
// 	roles: ["admin", "mecanico", "orcamentista"],
// },
// {
// 	text: "Serviços",
// 	icon: <CarRepairIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/servicos",
// 	roles: [],
// },
// {
// 	text: "Presets de Serviços",
// 	icon: <AssignmentIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/presets",
// 	roles: [],
// },
// {
// 	text: "Clientes",
// 	icon: (
// 		<PeopleAltOutlinedIcon fontSize="medium" htmlColor="#ffffff" />
// 	),
// 	route: "/clientes",
// 	roles: [],
// },
// {
// 	text: "Fornecedores",
// 	icon: <DeliveryDiningIcon fontSize="medium" htmlColor="#ffffff" />,
// 	route: "/fornecedores",
// 	roles: [],
// },
// {
// 	text: "Fornecedores",
// 	icon: <LocalShippingIcon fontSize="large" htmlColor="#ffffff" />,
// 	route: "/fornecedores",
// 	roles: [],
// },
// 	],
// };

const PricingMenuItems: IMenuSection = {
	description: "Precificação",
	routes: [
		// {
		// 	text: "Financeiro",
		// 	icon: <SwapHorizIcon fontSize="medium" htmlColor="#ffffff" />,
		// 	route: "/financeiro",
		// 	roles: ["it-admin"],
		// },
		{
			text: "Curva de Peças",
			icon: <QueryStatsIcon htmlColor="#ffffff" fontSize="medium" />,
			route: "/pricing-curve",
			roles: ["admin", "financeiro"],
		},
		// {
		// 	text: "Definição de Preços",
		// 	icon: <PaidOutlinedIcon htmlColor="#ffffff" fontSize="medium" />,
		// 	route: "/price",
		// 	roles: ["admin", "financeiro"],
		// },
	],
};

// const SupportMenuItems: IMenuSection = {
// 	description: "Suporte",
// 	routes: [
// 		{
// 			text: "Suporte",
// 			icon: <SupportAgentIcon fontSize="medium" htmlColor="#ffffff" />,
// 			route: "/suporte",
// 			roles: ["it-admin"],
// 		},
// 	],
// };

// const AdminMenuItems: IMenuSection = {
// 	description: "Admin",
// 	routes: [
// 		{
// 			text: "Usuários",
// 			icon: (
// 				<SupervisedUserCircleIcon
// 					fontSize="medium"
// 					htmlColor="#ffffff"
// 				/>
// 			),
// 			route: "/users",
// 			roles: ["it-admin"],
// 		},
// {
// 	text: "Contingências",
// 	icon: (
// 		<EngineeringOutlinedIcon
// 			htmlColor="#ffffff"
// 			fontSize="medium"
// 		/>
// 	),
// 	route: "/contingencias",
// 	roles: ["it-admin"],
// },
// 	],
// };

const MenuItems = [
	// DashboardMenuItems,
	ServicesMenuItems,
	// VehicleMenuItems,
	// ManagementMenuItems,
	PricingMenuItems,
	// SupportMenuItems,
	// AdminMenuItems,
];

const drawerWidth = 216;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			position: "relative",
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 24,
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
			backgroundColor: "#ffffff",
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: "60px",
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(7),
			},
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		toolbarButtons: {
			marginLeft: "auto",
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	})
);

export default function MiniDrawer(props: IDrawerProps) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState<any>();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	let navigate = useNavigate();
	let location = useLocation();

	const getUser = useCallback(async () => {
		if (auth.currentUser !== null) {
			const userData = await getUserByUuid(auth.currentUser!.uid);
			setUser(userData);
		}
	}, []);

	useEffect(() => {
		getUser();
	}, [getUser]);

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	async function handleSignout() {
		try {
			await signOut();
			navigate("/");
		} catch (error: any) {
			console.error(error);
		}
	}

	const menuItemsList = useMemo(() => {
		return (
			<List>
				{MenuItems.map((itemsArr, index) => (
					<div key={`section-${index}`}>
						<p style={{ fontSize: "8px" }}>
							{itemsArr.description}
						</p>
						{itemsArr.routes.map((item) => (
							<Link
								key={`link-${item.text}`}
								id={`link-${item.text}`}
								to={item.route}
								style={{
									color: "#fff",
									textDecoration: "none",
								}}
							>
								<ListItemButton
									key={item.text}
									sx={{
										":hover": {
											backgroundColor: "#303030",
										},
										backgroundColor:
											location.pathname === item.route ||
											(item.route !== "/" &&
												location.pathname.includes(
													item.route
												))
												? "#ff7221"
												: "",
									}}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.text} />
								</ListItemButton>
							</Link>
						))}
					</div>
				))}
			</List>
		);
	}, [location.pathname]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar elevation={0} position="fixed" style={{ zIndex: 15 }}>
				<Toolbar variant="dense">
					<Link to={"/orders"}>
						<img
							style={{ zIndex: 20 }}
							src={logoImage}
							alt="Logo Mult Injection"
							height={30}
						/>
					</Link>
					<IconButton
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						size="small"
						edge="start"
						style={{ marginBottom: "8px", marginLeft: "16px" }}
					>
						<MenuIcon htmlColor="#ffffff" />
					</IconButton>
					<div className={classes.toolbarButtons}>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={openMenu}
							onClose={handleClose}
						>
							<Grid
								container
								direction="column"
								style={{ padding: "12px" }}
							>
								<Grid
									direction="row"
									alignItems="center"
									container
									justifyContent="space-between"
									item
									spacing={2}
									style={{ marginBottom: "12px" }}
								>
									<Grid
										container
										item
										direction="column"
										spacing={0}
									>
										<p style={{ padding: 0, margin: 0 }}>
											{user?.name ?? ""}{" "}
											{user?.surname ?? ""}
										</p>
										<p style={{ padding: 0, margin: 0 }}>
											{auth.currentUser?.email}
										</p>
									</Grid>
									<Grid item>
										<Avatar
											sx={{ bgcolor: deepOrange[400] }}
										>
											{user?.name
												?.split(" ")[0]
												.charAt(0)}
											{user?.surname
												?.split(" ")[0]
												.charAt(0)}
										</Avatar>
									</Grid>
								</Grid>
								<Button
									style={{ justifyContent: "end" }}
									onClick={handleSignout}
								>
									Sair
								</Button>
							</Grid>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				style={{ zIndex: 1 }}
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div style={{ minHeight: "48px" }}></div>
				{menuItemsList}
			</Drawer>
			{props.children}
		</div>
	);
}
