import { TextField } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Autocomplete, Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icons from "../../../assets/icons/icons";
import HeaderLine from "../../../components/Content/HeaderLine";
import { ActionButtons } from "../../../components/GridActions";
import SvgIcon from "../../../components/Icons/SvgIcon";
import { getAllModelsFromManufacturer } from "../../../services/api/models";
import { getManufacturers } from "../../../services/fipe/fipe";
import { IOptions } from "../../../types/Options.types";
import { parseFipeMarcasOptions } from "../../../utils/helpers/parsers";
function Models() {
	const [data, setData] = useState<any[]>([]);
	const [manufacturersOptions, setManufacturersOptions] = useState<
		IOptions[]
	>([]);
	const [selectedManufacturer, setSelectedManufactuer] = useState<string>();
	let navigate = useNavigate();

	const getManufacturersOptions = useCallback(async () => {
		try {
			const res = await getManufacturers();
			const options = parseFipeMarcasOptions(res);

			setManufacturersOptions(options);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getManufacturersOptions();
	}, [getManufacturersOptions]);

	function onEdit(manufacturerId: any, modelId: any) {
		navigate(`/modelos/${manufacturerId as string}/${modelId as string}`);
	}

	useEffect(() => {
		async function getModels() {
			try {
				const models = await getAllModelsFromManufacturer(
					selectedManufacturer!
				);

				if (models.length) {
					setData(models);
				}
			} catch (error: any) {
				console.error(error);
			}
		}

		if (!!selectedManufacturer) {
			getModels();
		}
	}, [selectedManufacturer]);

	const columns: GridColDef[] = [
		{
			field: "Label",
			headerAlign: "left",
			headerClassName: "super-app-theme--header",
			align: "left",
			headerName: "Modelo",
			flex: 0.5,
		},
		{
			field: "years",
			headerAlign: "center",
			headerClassName: "super-app-theme--header",
			align: "center",
			headerName: "Anos",
			flex: 0.5,
			renderCell: (obj) => (
				<>
					{obj.row.years
						? `${obj.row.years[obj.row.years.length - 1]} a ${
								obj.row.years[0]
						  }`
						: "Finalize o cadastro do veículo"}
				</>
			),
		},
		{
			field: "fullfilled",
			headerClassName: "super-app-theme--header",
			headerName: "Cadastro Completo",
			headerAlign: "center",
			renderCell: (obj) => (
				<>
					<div style={{ marginTop: "32px" }}>
						{obj.row.fullfilled ? (
							<SvgIcon svgContent={icons.check} color="#009829" />
						) : (
							<SvgIcon svgContent={icons.error} color="#C70002" />
						)}
					</div>
				</>
			),
			flex: 0.2,
			align: "center",
		},
		{
			field: " ",
			headerClassName: "super-app-theme--header",
			headerName: "Ações",
			headerAlign: "center",
			renderCell: (obj) => {
				return (
					<>
						<ActionButtons
							onEdit={() =>
								onEdit(
									selectedManufacturer as string,
									obj.row.id
								)
							}
						/>
					</>
				);
			},
			flex: 0.2,
			align: "center",
		},
	];

	return (
		<>
			<HeaderLine title="Modelos" />

			<Autocomplete
				style={{ marginBottom: "16px" }}
				disablePortal
				options={manufacturersOptions}
				getOptionLabel={(option) => option.value}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Montadora"
						variant="standard"
					/>
				)}
				onChange={(_e: any, v: any) => {
					setSelectedManufactuer(v ? v.id : "");
				}}
			/>

			{!!selectedManufacturer && (
				<Box
					sx={{
						height: 300,
						width: "100%",
						"& .super-app-theme--header": {
							backgroundColor: "#ff7221",
						},
					}}
				>
					<DataGrid
						disableSelectionOnClick
						rows={data}
						columns={columns}
						pageSize={15}
						density="compact"
						autoHeight
					/>
				</Box>
			)}
		</>
	);
}
export { Models };
