import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../components/Snackbars/SmartSnackbar";
import { AuthContext } from "../../../modules/auth/Auth";
import { addOrder } from "../../../services/api/orders";
import {
	getManufacturerModels,
	getManufacturers,
	getModelYears,
} from "../../../services/fipe/fipe";
import { IFormModalProps } from "../../../types/Modal.types";
import { IOptions } from "../../../types/Options.types";
import { IOrderDto } from "../../../types/Order.types";
import {
	getOptionValueById,
	parseFipeMarcasOptions,
	parseFipeModelosOptions,
	parseFipeModelYearsOptions,
} from "../../../utils/helpers/parsers";
import { IInitialOrderFormInput } from "./OrderForm.form";

export interface IOrderFormProps extends Omit<IFormModalProps, "id"> {}

export default function OrderForm(props: IOrderFormProps) {
	const [loading, setLoading] = useState<boolean>(false);
	// const [openWarning, setOpenWarning] = useState<boolean>(false);
	// const [quotas, setQuotas] = useState<number>(100);
	const { setValue, getValues, watch, control, handleSubmit } =
		useForm<IInitialOrderFormInput>({
			defaultValues: {
				plate: "",
				year: "",
				manufacturer: "",
				model: "",
			},
		});

	const watchManufacturer = watch("manufacturer");
	const watchModel = watch("model");

	// const [data, setData] = useState<IPlacasData>();
	// const [lastPlate, setLastPlate] = useState<string>("");
	// const [selectedOption, setSelectedOption] = useState<IApiPlacasDado>();
	// const watchSource = watch("source");
	// const watchPlate = watch("plate");
	const [manufacturersOptions, setManufacturerOptions] = useState<IOptions[]>(
		[]
	);
	const [modelsOptions, setModelsOptions] = useState<IOptions[]>([]);
	const [yearsOptions, setYearsOptions] = useState<IOptions[]>([]);
	const [manufacturerDescription, setManufacturerDescription] =
		useState<string>("");
	const [modelDescription, setModelDescription] = useState<string>("");

	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const { companyTaxId, email } = useContext(AuthContext);

	// const getRemainingQuotas = useCallback(async () => {
	// 	try {
	// 		const remaining = await getQuotas();
	// 		setQuotas(remaining!.qtdConsultas!);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }, []);

	// useEffect(() => {
	// 	getRemainingQuotas();
	// }, [getRemainingQuotas]);

	const handleClose = () => {
		props.handleCloseModal(false);
	};

	// const handleOptionSelection = (value: string) => {
	// 	const option = data?.fipe.dados.filter(
	// 		(d: IApiPlacasDado) => d.codigo_fipe === value
	// 	)[0];
	// 	setSelectedOption(option);
	// };

	const getFipeManufacturerOptions = useCallback(async () => {
		const fipeManufacturers = await getManufacturers();
		const parsedOptions = parseFipeMarcasOptions(fipeManufacturers);
		setManufacturerOptions(parsedOptions);
	}, []);

	useEffect(() => {
		getFipeManufacturerOptions();
	}, [getFipeManufacturerOptions]);

	const getFipeManufacturerModelOptions = useCallback(async () => {
		setValue("model", "");
		setValue("year", "");
		const fipeManufacturersModels = await getManufacturerModels(
			parseInt(watchManufacturer) ?? 1
		);
		const parsedOptions = parseFipeModelosOptions(
			fipeManufacturersModels.Modelos
		);
		setModelsOptions(parsedOptions);
	}, [setValue, watchManufacturer]);

	useEffect(() => {
		getFipeManufacturerModelOptions();
	}, [getFipeManufacturerModelOptions, getValues]);

	const getFipeModelYearOptions = useCallback(async () => {
		if (watchManufacturer !== "" && watchModel !== "") {
			const fipeModelsYears = await getModelYears(
				watchManufacturer,
				watchModel
			);

			const manufacDescription = getOptionValueById(
				watchManufacturer,
				manufacturersOptions
			);
			const modDescription = getOptionValueById(
				watchModel,
				modelsOptions
			);

			setManufacturerDescription(manufacDescription);
			setModelDescription(modDescription);

			if (fipeModelsYears.length >= 0) {
				const parsedOptions =
					parseFipeModelYearsOptions(fipeModelsYears);
				setYearsOptions(parsedOptions);
			}
		}
	}, [manufacturersOptions, modelsOptions, watchManufacturer, watchModel]);

	useEffect(() => {
		getFipeModelYearOptions();
	}, [getFipeModelYearOptions]);

	const onSubmit: SubmitHandler<IInitialOrderFormInput> = async (
		formData: IInitialOrderFormInput
	) => {
		setLoading(true);
		try {
			let payload: IOrderDto = {
				id: "",
				// linkedIds: [
				// 	`${selectedOption?.codigo_marca}_${selectedOption?.codigo_modelo}`,
				// ],
				// manufacturer: capitalizeString(data!.MARCA),
				manufacturer: manufacturerDescription,
				manufacturerFipeId: formData.manufacturer,
				model: modelDescription,
				modelFipeId: formData.model,
				// fipeCode: selectedOption!.codigo_fipe,
				fipeCode: "",
				type: "draft",
				year: formData.year.split("-")[0],
				isActive: true,
				createdDate: new Date(),
				externalId: formData.externalId ?? "",
				plate: formData.plate,
				services: [],
				parts: [],
				proceedings: [],
				modelAdjustmentFactor: 0,
				company: companyTaxId!,
				createdBy: email!,
				sequentialId: 0,
			};

			// const modelData = await getModelById(
			// 	`${selectedOption?.codigo_marca}_${selectedOption?.codigo_modelo}`
			// );
			// if (!modelData) {
			// 	await addOrder(payload);
			// 	props.refreshParentHandler(!props.refreshParent);
			// 	setLoading(false);
			// 	handleClose();
			// }

			// if (
			// 	!!modelData?.adjustmentFactor &&
			// 	!!modelData?.dealershipEstimatedPrice &&
			// 	!!modelData?.fipePrice
			// ) {
			// 	payload.modelAdjustmentFactor = modelData!.adjustmentFactor;
			// 	payload.services = [];
			// 	payload.type = setOrderType(payload);

			await addOrder(payload);
			props.refreshParentHandler(!props.refreshParent);
			setLoading(false);
			handleClose();
			// } else {
			// 	await addOrder(payload);
			// 	setLoading(false);
			// 	setOpenWarning(true);
			// }

			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: "Orçamento criado com sucesso",
				severity: "success",
			});
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	// const handleWarningClose = () => {
	// 	props.refreshParentHandler(!props.refreshParent);
	// 	setLoading(false);
	// 	handleClose();
	// 	setOpenWarning(false);
	// };

	// const getDataFromPlate = async () => {
	// 	try {
	// 		setLoading(true);
	// 		setLastPlate(watchPlate);
	// 		const res = await getDataByPlate(watchPlate);
	// 		setData(res);
	// 	} catch (error: any) {
	// 		setSmartSnackbarConfig({
	// 			open: !smartSnackbarConfig.open,
	// 			message: error.message as string,
	// 			severity: "error",
	// 		});
	// 		console.error(error);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	return (
		<div>
			{loading && <Loading />}

			<>
				<form id="orderForm" onSubmit={handleSubmit(onSubmit)}>
					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Grid item xs={12} sm={12}>
							<Controller
								name="plate"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										margin="dense"
										id="plate"
										label="Placa do Veículo"
										variant="outlined"
										type="text"
										fullWidth
										onChange={onChange}
										value={value}
										inputProps={{
											maxLength: 7,
											style: {
												textTransform: "uppercase",
											},
										}}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} sm={12}>
							<Controller
								name="externalId"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										autoFocus
										margin="dense"
										id="externalId"
										label="Cód. Externo (Opcional)"
										type="string"
										helperText="Código da OS no Sistema de Gestão usado na oficina"
										fullWidth
										variant="outlined"
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={12}
							style={{ marginBottom: "8px" }}
						>
							{" "}
							<InputLabel id="manufacturer">Montadora</InputLabel>
							<FormControl fullWidth>
								<Controller
									control={control}
									name="manufacturer"
									defaultValue={""}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="manufacturer"
											id="select-manufacturer"
											fullWidth
											margin="dense"
											variant="outlined"
											onChange={onChange}
											value={value}
										>
											{manufacturersOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={12}>
							<InputLabel id="model">Modelo</InputLabel>
							<FormControl fullWidth>
								<Controller
									control={control}
									name="model"
									defaultValue={""}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="model"
											id="select-model"
											margin="dense"
											variant="outlined"
											onChange={onChange}
											value={value}
										>
											{modelsOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={12}>
							<InputLabel id="year">Ano</InputLabel>
							<FormControl fullWidth>
								<Controller
									control={control}
									name="year"
									defaultValue={""}
									render={({
										field: { onChange, value },
									}) => (
										<Select
											labelId="year"
											id="select-year"
											margin="dense"
											variant="outlined"
											onChange={onChange}
											value={value}
										>
											{yearsOptions.map(
												(o: IOptions, i: number) => {
													return (
														<MenuItem
															key={i}
															value={o.id}
														>
															{o.value}
														</MenuItem>
													);
												}
											)}
										</Select>
									)}
								/>
							</FormControl>
						</Grid>
						{/* <Grid item xs={12} sm={12}>
								<Controller
									name="year"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<TextField
											margin="dense"
											id="year"
											label="Ano/Modelo"
											type="text"
											fullWidth
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							</Grid> */}
					</Grid>
				</form>

				{/* <Grid
						justifyContent={
							quotas < 100 ? "space-between" : "flex-end"
						}
						spacing={2}
						alignItems="baseline"
						container
					>
						{quotas < 100 && (
							<Grid item>
								<Typography variant="body2">
									Consultas restantes: {quotas}. Contate o
									desenvolvedor.
								</Typography>
							</Grid>
						)}
						<Grid item>
							<Button
								disabled={
									watchPlate.length !== 7 &&
									watchPlate !== lastPlate
								}
								onClick={getDataFromPlate}
								color="primary"
							>
								Buscar Dados
							</Button>
						</Grid>
					</Grid> */}

				{/* {data && (
						<div style={{ marginTop: "16px" }}>
							<Typography variant="h6">
								Dados do veículo
							</Typography>

							<Typography variant="body1">
								{`${data.MARCA} ${data.MODELO}  ${
									data.anoModelo
								} ${data.extra.combustivel.toUpperCase()}	`}
							</Typography>

							<Typography variant="h6">
								Selecione entre os modelos disponíveis
							</Typography>

							<RadioGroup
								name="select-fipe-option"
								onChange={(ev) =>
									handleOptionSelection(ev.target.value)
								}
							>
								{data.fipe.dados.map((o: IApiPlacasDado) => {
									return (
										<FormControlLabel
											value={o.codigo_fipe}
											id={o.codigo_fipe}
											key={o.codigo_fipe}
											control={
												<Radio
													size="small"
													color="primary"
												/>
											}
											label={`${o.codigo_fipe} - ${o.texto_modelo}`}
										/>
									);
								})}
							</RadioGroup>
						</div>
					)} */}
			</>

			{/* <Dialog open={openWarning} onClose={handleClose}>
				<DialogTitle id="alert-dialog-title">
					Dados do modelo precisam ser preenchidos
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Para que o valor dos serviços seja calculado
						corretamente, é necessário que o cadastro do modelo do
						veículo seja preenchido e os devidos fatores de correção
						sejam calculados e aplicados.
						<br />
						<br />
						Você será redirecionado para a página de edição do
						modelo.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Link
						to={`/modelos/${selectedOption?.codigo_marca}/${selectedOption?.codigo_modelo}`}
						target="_blank"
					>
						<Button
							color="primary"
							onClick={handleWarningClose}
							autoFocus
						>
							Ir para a página de edição
						</Button>
					</Link>
				</DialogActions>
			</Dialog> */}

			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}

export { OrderForm };
