import { AddShoppingCart } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Chip,
	Divider,
	Drawer,
	Grid,
	IconButton,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditableOrderParts from "../../../../../../components/Content/EditableOrderPartsGrid";
import PartsSummary from "../../../../../../components/Content/PartsSummary";
import Loading from "../../../../../../components/Loading";
import FormModal from "../../../../../../components/Modals/FormModal";
import { AuthContext } from "../../../../../../modules/auth/Auth";
import { getCompanyByTaxId } from "../../../../../../services/api/companies";
import {
	deleteOrderPart,
	getOrder,
} from "../../../../../../services/api/orders";
import { ICompany } from "../../../../../../types/Company.types";
import { IOrderDto } from "../../../../../../types/Order.types";
import {
	IOrderPartsDto,
	IPartEstimateOnSummaryDto,
	IPartSummarySupplier,
	IPartsSummary,
} from "../../../../../../types/Parts.types";
import {
	setPartStatusColor,
	setPartStatusText,
} from "../../../../../../utils/helpers/parsers";
import OrderPartsForm from "./OrderPartsForm";

interface IOrderPartsDetailsProps {
	modelDescription: string;
	modelPlate: string;
}

export default function OrderPartsDetails(props: IOrderPartsDetailsProps) {
	let { id } = useParams<{ id: string }>();
	const { companyTaxId } = useContext(AuthContext);
	const [order, setOrder] = useState<IOrderDto>();
	const [parts, setParts] = useState<IOrderPartsDto[] | undefined>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [refreshSum, setRefreshSum] = useState<boolean>(false);
	const [companyData, setCompanyData] = useState<ICompany>();
	// const [partsFullView, setPartsFullView] = useState<boolean>(true);
	const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
	const [selectedPart, setSelectedPart] = useState<
		IOrderPartsDto | undefined
	>();
	const [summary, setSummary] = useState<IPartsSummary>({ suppliers: [] });

	const getCompanyData = useCallback(async () => {
		try {
			const data = await getCompanyByTaxId(companyTaxId!);
			setCompanyData(data as ICompany);
		} catch (error: any) {
			console.error(error);
		}
	}, [companyTaxId]);

	useEffect(() => {
		getCompanyData();
	}, [getCompanyData, refresh]);

	const toggleDrawer =
		(open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return;
			}

			setSummaryOpen(open);
		};

	useEffect(() => {
		const estimates = parts?.flatMap((order) =>
			order.estimates.map((estimate) => ({
				...estimate,
				description: order.description,
			}))
		) as IPartEstimateOnSummaryDto[];

		// Filtra as partes que têm a flag isBestOption como true
		const bestOptionParts = estimates?.filter((part) => part.isBestOption);

		// Agrupa as partes pelo nome do fornecedor (provider)
		const suppliersMap: { [key: string]: IPartEstimateOnSummaryDto[] } = {};
		bestOptionParts?.forEach((part) => {
			if (!suppliersMap[part.provider]) {
				suppliersMap[part.provider] = [];
			}
			suppliersMap[part.provider].push(part);
		});

		// Transforma o mapa de fornecedores em um array de suppliers
		const suppliersResult: IPartSummarySupplier[] = Object.keys(
			suppliersMap
		).map((provider) => ({
			name: provider,
			parts: suppliersMap[provider],
			deliveryTax: 0,
		}));

		setSummary({ suppliers: suppliersResult });
	}, [parts]);

	async function removeOrderPart(partId: string) {
		try {
			setLoading(true);
			await deleteOrderPart(id!, partId);
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function handleCancel() {
		const mustCancel = window.confirm(
			"Tem certeza que deseja cancelar esta operação?"
		);
		if (mustCancel) {
			setOpenModal(false);
			setSelectedPart(undefined);
		}
	}

	const orderData = useCallback(async () => {
		try {
			const data = await getOrder(id!);
			setOrder(data as IOrderDto);
			setSelectedPart(undefined);
		} catch (error: any) {
			console.error(error);
		}
	}, [id]);

	useEffect(() => {
		orderData();
	}, [orderData, refresh, refreshSum]);

	useEffect(() => {
		setParts(order?.parts);
	}, [order?.parts, order]);

	const editPart = (e: any) => {
		setSelectedPart(e);
		setOpenModal(true);
	};

	async function generateWhatsAppMessage() {
		let itens = "";
		parts?.forEach(
			(p: IOrderPartsDto) => (itens = itens + `• ${p.description}\n`)
		);
		const message = `Olá, tudo bem?\nPara um ${order?.manufacturer} ${order?.model} ${order?.year} você tem os seguintes itens?\n\n${itens}\nPlaca: ${props.modelPlate}`;
		await navigator.clipboard.writeText(message);
	}

	return (
		<>
			{loading && <Loading />}
			<Grid
				container
				justifyContent="space-between"
				alignItems="flex-end"
				style={{ marginBottom: "8px" }}
			>
				<Grid
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-end"
					spacing={1}
					item
					lg={12}
					md={12}
					sm={12}
				>
					{/* <Grid item>
						<Button
							variant="contained"
							color="primary"
							endIcon={<Visibility />}
							size="small"
							onClick={() => setPartsFullView(!partsFullView)}
						>
							Visualização
						</Button>
					</Grid> */}
					{summary!.suppliers.length > 0 && (
						<Grid item>
							<Button
								endIcon={<EqualizerIcon />}
								variant="contained"
								color="primary"
								size="small"
								onClick={toggleDrawer(true)}
							>
								Resumo
							</Button>
							<Drawer
								anchor={"right"}
								open={summaryOpen}
								onClose={toggleDrawer(false)}
							>
								<PartsSummary
									companyPartsPricing={
										companyData?.partsPricing!
									}
									suppliers={summary?.suppliers ?? []}
								/>
							</Drawer>
						</Grid>
					)}

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							endIcon={<AddShoppingCart />}
							size="small"
							onClick={() => setOpenModal(true)}
						>
							Adicionar Peça{" "}
						</Button>
					</Grid>

					{(parts?.length ?? [].length) > 0 && (
						<Grid item>
							<Button
								variant="contained"
								color="success"
								endIcon={<WhatsAppIcon />}
								size="small"
								onClick={() => generateWhatsAppMessage()}
							>
								Copiar Mensagem
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>

			<br />
			<Grid
				container
				justifyContent="space-between"
				alignItems={"flex-end"}
				spacing={1}
			></Grid>
			{parts?.map((p: IOrderPartsDto) => (
				<Accordion key={p.id} style={{ marginBottom: "8px" }}>
					<AccordionSummary>
						<Grid
							container
							justifyContent="space-between"
							alignItems={"flex-end"}
							spacing={1}
						>
							<Grid
								item
								alignItems="center"
								container
								justifyContent={"flex-start"}
								spacing={1}
								sm={11}
							>
								<Grid
									item
									style={{
										marginLeft: "8px",
										textAlign: "start",
									}}
								>
									<strong>{p.description}</strong>
								</Grid>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
									style={{ borderColor: "white" }}
								/>
								<Grid item>
									<Chip
										label={setPartStatusText(
											p.status ?? ""
										)}
										color={setPartStatusColor(
											p.status ?? ""
										)}
										size="small"
									/>
								</Grid>
							</Grid>
							<Grid
								sm={1}
								item
								container
								alignItems={"bottom"}
								justifyContent={"flex-end"}
							>
								<Grid item>
									<IconButton
										style={{ zIndex: 1000 }}
										color="inherit"
										aria-label="edit"
										size="small"
										onClick={(e: any) => {
											editPart(p);
											e.stopPropagation();
										}}
									>
										<EditIcon />
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton
										style={{ zIndex: 1000 }}
										color="default"
										aria-label="delete"
										size="small"
										onClick={async (e: any) => {
											e.stopPropagation();
											await removeOrderPart(p.id);
										}}
									>
										<HighlightOffIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</AccordionSummary>
					<AccordionDetails>
						<EditableOrderParts
							fullView={true}
							partsPricing={companyData?.partsPricing!}
							// fullView={partsFullView}
							orderId={id!}
							part={p}
							refreshParent={refreshSum}
							refreshParendHandler={setRefreshSum}
						/>
					</AccordionDetails>
				</Accordion>
			))}
			<FormModal
				title={`${!!selectedPart ? "Adicionar" : "Editar"} peça`}
				description={`${
					!!selectedPart ? "Adicionar" : "Editar"
				} uma peça no orçamento`}
				isOpen={openModal}
				handleCancel={handleCancel}
				formToSubmitId="orderPartsForm"
			>
				<OrderPartsForm
					id={id!}
					companyPartsPricing={companyData?.partsPricing!}
					part={selectedPart}
					refreshParent={refresh}
					refreshParentHandler={setRefresh}
					handleCloseModal={setOpenModal}
				/>
			</FormModal>
		</>
	);
}
