import { onAuthStateChanged } from "firebase/auth";
import { createContext, useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { getUserByUuid } from "../../services/api/users";
import { auth } from "../../services/config/environments";

interface IAuthUser {
	name?: string;
	email?: string;
	companyTaxId?: string;
	isAuthenticated: boolean;
}

const baseUser: IAuthUser = {
	isAuthenticated: false,
};

const AuthContext = createContext(baseUser);

function AuthProvider({ children }: any) {
	const [currentUser, setCurrentUser] = useState<IAuthUser>(baseUser);
	const [pending, setPending] = useState(true);

	const userData = useCallback(async (uuid?: string) => {
		if (uuid) {
			const userData = await getUserByUuid(uuid);

			const user: IAuthUser = {
				isAuthenticated: true,
				name: userData?.name,
				companyTaxId: userData?.company,
				email: userData?.email,
			};

			setCurrentUser(user);
		}
	}, []);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			userData(user?.uid);
		});
		setPending(false);
	}, [userData]);

	if (pending) {
		return (
			<>
				<Loading />
			</>
		);
	}

	return (
		<AuthContext.Provider value={currentUser}>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
