import { Button, CardActions, CardContent } from "@material-ui/core";
import { Card, Chip, Grid, Typography } from "@mui/material";
import { ICompany } from "../../types/Company.types";

interface ICompanyCardProps {
	company: ICompany;
}

export default function CompanyCard(props: ICompanyCardProps) {
	return (
		<>
			<Grid
				item
				xs={12}
				sm={6}
				md={3}
				lg={3}
				style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "8px",
				}}
			>
				<Card
					sx={{
						minWidth: 275,
						flexDirection: "column",
						textAlign: "left",
					}}
				>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							{props.company.name}
						</Typography>
						<Typography variant="h6" gutterBottom>
							{props.company.taxId}
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							{props.company.phone}
						</Typography>
						<Typography variant="subtitle2" gutterBottom>
							{props.company.email}
						</Typography>
						<div style={{ marginBottom: "8px" }}>
							<p
								style={{
									display: "inline",
									fontWeight: "bold",
									fontSize: "14px",
									alignItems: "bottom",
									marginRight: "4px",
								}}
							>
								Status
							</p>
							<Chip
								size="small"
								label={
									props.company.status ? "Ativo" : "Inativo"
								}
								color={
									props.company.status ? "success" : "error"
								}
							/>
						</div>
						<p
							style={{
								display: "inline",
								fontWeight: "bold",
								fontSize: "14px",
								alignItems: "bottom",
								marginRight: "4px",
							}}
						>
							Plano
						</p>

						<Chip size="small" label={props.company.plan} />
					</CardContent>
					<CardActions>
						<Button
							variant="contained"
							color="primary"
							size="small"
						>
							Detalhes
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</>
	);
}
