import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loading from "../../../../../../components/Loading";
import SmartSnackbar, {
	ISmartSnackbarConfig,
} from "../../../../../../components/Snackbars/SmartSnackbar";
import { getTemplateById } from "../../../../../../services/api/templates";
import { IFormModalProps } from "../../../../../../types/Modal.types";
import { IOptions } from "../../../../../../types/Options.types";
import { parseTemplateOptions } from "../../../../../../utils/helpers/parsers";
import {
	IImportTemplateFormInput,
	emptyImportTemplateForm,
} from "./ImportTemplateForm.form";
import { emptyServiceForm } from "./ServiceForm.form";

export interface IImportTemplateModalForm extends Omit<IFormModalProps, "id"> {}

export default function ImportTemplateForm(props: IImportTemplateModalForm) {
	const { control, handleSubmit, reset } = useForm<IImportTemplateFormInput>({
		defaultValues: emptyImportTemplateForm,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<IOptions[]>([]);
	const [template, setTemplate] = useState<any>();
	const [smartSnackbarConfig, setSmartSnackbarConfig] =
		useState<ISmartSnackbarConfig>({
			open: false,
			message: "",
			severity: "success",
		});

	const handleClose = () => {
		reset(emptyServiceForm);
		props.handleCloseModal(false);
	};

	const templatesOptions = useCallback(async () => {
		try {
			const data = await getTemplateById("all_templates");
			const templates = data!.templates;
			const templateOptions = parseTemplateOptions(templates) ?? [];
			setOptions(templateOptions);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		templatesOptions();
	}, [templatesOptions]);

	const onSubmit: SubmitHandler<IImportTemplateFormInput> = async () => {
		setLoading(true);
		try {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: `Agrupamento importado com sucesso ${template}`,
				severity: "success",
			});
			handleClose();
		} catch (error: any) {
			setSmartSnackbarConfig({
				open: !smartSnackbarConfig.open,
				message: error.message as string,
				severity: "error",
			});
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{loading && <Loading />}

			<form id="templateImportForm" onSubmit={handleSubmit(onSubmit)}>
				<FormControl fullWidth>
					<Controller
						control={control}
						name="description"
						defaultValue={emptyImportTemplateForm.description}
						render={() => (
							<Autocomplete
								style={{ marginBottom: "16px" }}
								options={options ?? [{ id: "", label: "" }]}
								noOptionsText="Nenhum agrupamento encontrado"
								getOptionLabel={(option: any) => option.value}
								renderInput={(params: any) => (
									<TextField
										{...params}
										label="Agrupamento"
									/>
								)}
								onChange={(_e: any, v: any) => {
									setTemplate(v ? v.id : "");
								}}
							/>
						)}
					/>
				</FormControl>
			</form>
			{smartSnackbarConfig.open && (
				<SmartSnackbar
					message={smartSnackbarConfig.message}
					severity={smartSnackbarConfig.severity}
					open={smartSnackbarConfig.open}
				/>
			)}
		</div>
	);
}
