import { Link, Typography } from "@material-ui/core";

function Copyright() {
	return (
		<div
			style={{
				position: "absolute",
				bottom: 0,
				width: "100vw",
				textAlign: "center",
				paddingBottom: "8px",
			}}
		>
			<Typography variant="body2" color="textSecondary" align="center">
				{"Copyright © "}
				<Link color="inherit" href="https://multinjection.com.br/">
					OmniCore Brasil Ltda.
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
		</div>
	);
}

export { Copyright };
