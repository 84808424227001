import { Grid, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CardsGrid from "../../../components/Content/CardsGrid";
import CompanyCard from "../../../components/Content/CompanyCard";
import HeaderLine from "../../../components/Content/HeaderLine";
import { getAllCompanies } from "../../../services/api/companies";
import { ICompany } from "../../../types/Company.types";

function Companies() {
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const getCompanies = useCallback(async () => {
		try {
			const companies = await getAllCompanies();
			setCompanies(companies ?? []);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	useEffect(() => {
		if (searchTerm !== "") {
			const filteredCompanies = companies.filter((i: ICompany) =>
				i.taxId.startsWith(searchTerm)
			);
			setFilteredCompanies(filteredCompanies);
		} else {
			setFilteredCompanies(companies);
		}
	}, [companies, searchTerm]);

	return (
		<>
			<HeaderLine title="Gestão de Cadastros" />{" "}
			<Grid justifyContent="flex-start">
				<Grid item sm={12}>
					<TextField
						style={{ marginBottom: "16px", width: "100%" }}
						id="standard-basic"
						label="Busque uma empresa pelo CNPJ"
						variant="standard"
						value={searchTerm}
						onChange={(v) => setSearchTerm(v.target.value)}
					/>
				</Grid>
			</Grid>
			<CardsGrid>
				<>
					{filteredCompanies.map((item: ICompany) => {
						return <CompanyCard company={item} />;
					})}
				</>
			</CardsGrid>
		</>
	);
}

export { Companies };
